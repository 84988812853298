import { fb, validators } from '../../../lib/form';

export function getContactModel(data) {
  
  let serviceList = ["Ants", "Spiders", "Termites", "Rodents", "Cockroaches", "Flying Insects", "Bee's", 'Birds', 'Possums', "Biting Insects"].map(x => { return { name: x, value: x } });
  serviceList = [{name: 'Please Select*', value: ''}, ...serviceList];

  const model = fb.group({
    firstName: [data.firstName || '', [validators.Required()], { label: 'First Name', type: 'text' }],
    lastName: [data.lastName || '', [validators.Required()], { label: 'Last Name', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    phone: [data.phone || '', [validators.Required()], { label: 'Mobile Number', type: 'text' }],
    address: [data.address || '', [validators.Required()], { label: 'Address', type: 'text' }],
    services: [data.services || '', [validators.Required()], { label: null, type: 'select', options: serviceList }],
    property: [data.property || '', [validators.Required()], { label: 'e.g. I live in a single story 3 bedroom house.', type: 'text' }], 
    issues: [data.issues || '', [validators.Required()], { label: 'e.g. I am currently experiencing a cockroach infestation in the walls of my home.', type: 'textarea', style: { rows: 6 }}],
    attachments: [data.attachments || '', [], { label: 'Attachments', type: 'file' }]
  }); 
  return model;
}