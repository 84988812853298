import { css } from '@emotion/core';

export default {
  rotatingReviewPanel: css({
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: 'transparent',
    '& .slick-prev:before, .slick-next:before': {
      color: 'black',
    },
    '& .slick-next': {
      right: '-2%',
    },
    '& .slick-prev': {
      left: '-2%',
    },
  }),
  sliderImageWrapper: {
    height: '100%',
  },
  sliderImageWrapper2: {
    height: '100%',
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
    margin: 'auto',
    width: '92%',
  },
  reviewContent: css({
    textAlign: 'left',
    paddingBottom: '1.3rem',
    marginBottom: '0',
    fontSize: '.8rem',
  }),
  starsImage: css({
    paddingBottom: '1.5rem',
  }),
  reviewer: css({
    textAlign: 'left',
    fontWeight: '600',
    fontSize: '.8rem',
  }),
  image_icon: css({
    borderColor: 'rgba(255,255,255,0.4)',
    fontFamily: 'Georgia,serif',
    fontSize: '50px',
    textAlign: 'left',
    display: 'inline-block',
    borderRadius: '100px',
    width: '50px',
    height: '50px',
    marginBottom: '33px',
    lineHeight: '70px',
    backgroundSize: 'cover',
    border: '2px solid rgba(0,0,0,0.13)',
  }),
};
