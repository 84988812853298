import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";

export function PestControlTiles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const iconImage = cmsUtils.payload(item, "IconImage");
  const iconImageUrl = utils.site.resourcePath(iconImage);
  const hoverIconImage = cmsUtils.payload(item, "HoverIconImage");
  const hoverIconImageUrl = utils.site.resourcePath(hoverIconImage);
  const pageLink = cmsUtils.payload(item, "PageLink");
  const pageTitle = cmsUtils.payload(item, "PageTitle");

  const cssClass = utils.classNames(
    "cms_item",
    "pestControlTiles",
    item.cssClass || item.anchorName || ""
  );

  return (
    <>
      <section
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <a className='pestControlTilesContainer' href={pageLink}>
          <div
            className='pc_image'
            style={{
              background: utils.css.bgUrlStyle(iconImageUrl),
              backgroundSize: "contain",
            }}
          ></div>
          <div
            className='pc_image_hover'
            style={{
              background: utils.css.bgUrlStyle(hoverIconImageUrl),
              backgroundSize: "contain",
            }}
          ></div>
          <div className='pc_title'>{pageTitle}</div>
          <div className='pc_btn btn'>LEARN MORE</div>
        </a>
      </section>
    </>
  );
}
