import React from "react";
import cmsUtils from "../../utils/cmsUtils";
import { Item } from "../../items/item";
import utils from "../../../utils";

export function PestControlTilePanel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  const bgColor = cmsUtils.payload(item, "BgColor");
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );

  const cssClass = utils.classNames(
    "cms_item",
    "PestControlTilePanel",
    item.cssClass || item.anchorName || ""
  );

  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));

  return (
    <>
      <section
        className={cssClass}
        style={{
          backgroundColor: `${bgColor}`,
          backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
        }}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div className='container'>
          <div className='pestControlTilePanel_container'>{subItemsElts}</div>
        </div>
      </section>
    </>
  );
}
